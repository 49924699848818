import { response } from '@api/helpers';
import brokerApi from '@api/endpoints/broker';
import waitlistApi from '@api/endpoints/waitlist';

export function alpacaOnboarding (payload) {
  return async () => response(brokerApi.onboarding(payload));
}

export function alpacaAccountInfo () {
  return async () => response(brokerApi.fetchAlpacaAccount());
}

export function editAlpacaAccountInfo (payload) {
  return async () => response(brokerApi.changeAlpacaAccount(payload));
}

export function alpacaAccountTradingAccountInfo () {
  return async () => response(brokerApi.fetchAccountTradingInfo());
}

export function fetchACHRelationship () {
  return async () => response(brokerApi.fetchACHRelationship());
}

export function deleteACHRelationship () {
  return async () => response(brokerApi.deleteACHRelationship());
}

export function createACHRelationship (body) {
  return async () => response(brokerApi.createACHRelationship(body));
}

export function deposit (body) {
  return async () => response(brokerApi.transfer(body));
}

export function getBrokerageAccountData () {
  return async () => response(brokerApi.fetchBrokerAgeAccount());
}

export function waitlistRegister (payload) {
  return async () => response(waitlistApi.register(payload));
}

export function submitDocuments (payload) {
  return async () => response(brokerApi.submitDocuments(payload));
}

export function closeAccount (payload) {
  return async () => response(brokerApi.closeAccount(payload));
}
