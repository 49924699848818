import companyApi from '@api/endpoints/company';
import { response } from '@api/helpers';

export function getCompany (companyName, makePain = true) {
  return async () => {
    const res = await response(companyApi.fetch(companyName));

    if (makePain) {
      return {
        amount: res.amount,
        proportionInPortfolio: res.proportion_in_portolio,
        risk: res.risk,
        usersInStock: res.total_users_in_stock,
        ...res,
      };
    }

    return res;
  };
}

export function deleteCompany (companyName) {
  return async () => response(companyApi.delete(companyName));
}
