import * as Tecton from 'q2-tecton-sdk';
import { debounce } from 'lodash';

export const keepAlive = (tecton: Tecton.IPlatformCoreAPI) => {
  if (typeof tecton.actions.keepAlive === 'function') {
    const debouncedKeepAlive = debounce(tecton.actions.keepAlive, 900);
    document.addEventListener('click', debouncedKeepAlive, false);
    document.addEventListener('scroll', debouncedKeepAlive, false);
    document.addEventListener('mousemove', debouncedKeepAlive, false);
    document.addEventListener('keypress', debouncedKeepAlive, false);
  }
};
