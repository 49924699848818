import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import * as routes from './names';
import * as components from './components';

import UnrestrictedRoute from '~/authentication/UnrestrictedRoute';
import PrivateRoute from '~/authentication/PrivateRoute';

import SideBarLayout from '~/ui/layouts/SideBarLayout';
import FullScreenLayout from '~/ui/layouts/FullScreenLayout';

export default [
  {
    hasAccess: () => true,
    getComponent: () => (
      <UnrestrictedRoute
        path={routes.GET_STARTED_0}
        component={props => (
          <FullScreenLayout>
            <components.OnboardingNew key={routes.GET_STARTED_0} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: () => true,
    getComponent: () => (
      <Route
        path={routes.LOADING}
        component={props => (
          <FullScreenLayout>
            <components.OnboardingLoaderScreen key={routes.LOADING} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: () => true,
    getComponent: () => (
      <UnrestrictedRoute
        path={routes.LOGIN}
        component={props => (
          <FullScreenLayout>
            <components.LoginPage key={routes.LOGIN} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: () => true,
    getComponent: () => (
      <UnrestrictedRoute
        path={routes.RESET_PASSWORD}
        component={props => (
          <FullScreenLayout>
            <components.ResetPassword key={routes.RESET_PASSWORD} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: () => true,
    getComponent: () => (
      <UnrestrictedRoute
        path={routes.NEW_PASSWORD}
        component={props => (
          <FullScreenLayout>
            <components.NewPassword key={routes.NEW_PASSWORD} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ hasAlpacaAccount, closure }) => hasAlpacaAccount && closure,
    getComponent: ({ hasAlpacaAccount }) => (
      <PrivateRoute
        exact
        path={routes.MAIN}
        component={(props) => (
          <SideBarLayout>
            <components.Settings
              key={routes.MAIN}
              hasAlpacaAccount={hasAlpacaAccount}
              {...props}
            />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ closure }) => !closure,
    getComponent: () => (
      <PrivateRoute
        exact
        path={routes.MAIN}
        component={props => (
          <SideBarLayout>
            <components.PerformancePage key={routes.MAIN} {...props} />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ closure }) => !closure,
    getComponent: ({ hasAlpacaAccount }) => (
      <PrivateRoute
        exact
        path={routes.PORTFOLIO}
        component={(props) => (
          <SideBarLayout>
            <components.MainPage
              key={routes.PORTFOLIO}
              hasAlpacaAccount={hasAlpacaAccount}
              {...props}
            />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ closure }) => !closure,
    getComponent: ({ hasAlpacaAccount }) => (
      <PrivateRoute
        exact
        path={routes.FUND_WITHDRAW}
        additionalPermissions={hasAlpacaAccount}
        noAdditionalPermissionsComponent={(props) => (
          <Redirect
            to={{
              pathname: routes.ONBOARDING_ALPACA,
              state: { from: props.location },
            }}
          />
        )}
        component={props => (
          <SideBarLayout>
            <components.FundWithdraw key={routes.FUND_WITHDRAW} {...props} />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ hasAlpacaAccount }) => !hasAlpacaAccount,
    getComponent: () => (
      <PrivateRoute
        path={routes.MAIN}
        exact
        component={(props) => (
          <SideBarLayout>
            <components.MainPage key={routes.MAIN} {...props} />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ hasAlpacaAccount, closure }) => true,
    getComponent: ({ hasAlpacaAccount }) => (
      <PrivateRoute
        exact
        path={routes.NEW_ACCOUNT}
        component={(props) => (
          <SideBarLayout>
            <components.Account
              key={routes.NEW_ACCOUNT}
              hasAlpacaAccount={hasAlpacaAccount}
              {...props}
            />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: () => true,
    getComponent: () => (
      <PrivateRoute
        exact
        path={routes.FAQ}
        component={(props) => (
          <SideBarLayout>
            <components.FAQ
              key={routes.FAQ}
              {...props}
            />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ closure }) => !closure,
    getComponent: () => (
      <PrivateRoute
        exact
        path={routes.IMPACT}
        component={props => (
          <SideBarLayout>
            <components.ImpactPage key={routes.IMPACT} {...props} />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ closure }) => !closure,
    getComponent: () => (
      <PrivateRoute
        exact
        path={routes.GROWTH}
        component={props => (
          <SideBarLayout>
            <components.GrowthPage key={routes.GROWTH} {...props} />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ hasAlpacaAccount }) => !hasAlpacaAccount,
    getComponent: () => (
      <PrivateRoute
        exact
        path={routes.ONBOARDING_ALPACA}
        component={props => (
          <FullScreenLayout>
            <components.OnboardingAlpaca key={routes.ONBOARDING_ALPACA} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ hasAlpacaAccount }) => !hasAlpacaAccount,
    getComponent: () => (
      <Route
        exact
        path="/onboarding-alpaca-to-test"
        component={props => (
          <FullScreenLayout>
            <components.AlpacaOnboarding key="onboarding-alpaca-to-test" {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ hasAlpacaAccount, isExternalUser }) => !hasAlpacaAccount && isExternalUser,
    getComponent: () => (
      <Route
        exact
        path="/onboarding-alpaca-to-test-external"
        component={props => (
          <FullScreenLayout>
            <components.AlpacaOnboardingExternal key="/onboarding-alpaca-to-test-external" {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ hasAlpacaAccount, isExternalUser }) => !hasAlpacaAccount && isExternalUser,
    getComponent: () => (
      <UnrestrictedRoute
        exact
        path={routes.GET_STARTED_EXTERNAL}
        component={props => (
          <FullScreenLayout>
            <components.OnboardingExternal key={routes.GET_STARTED_EXTERNAL} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: () => true,
    getComponent: () => (
      <PrivateRoute
        exact
        path={routes.KYC}
        component={props => (
          <FullScreenLayout>
            <components.KYC key={routes.KYC} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: () => true,
    getComponent: () => (
      <Route
        path={routes.ANY}
        component={props => (
          <FullScreenLayout>
            <components.PageNotFound key={routes.ANY} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
];
