export const GET_STARTED_0 = '/get-started';
export const GET_STARTED_1 = '/get-started-1';
export const GET_STARTED_2 = '/get-started-2';

export const GET_STARTED_EXTERNAL = '/external/get-started';

export const LOADING = '/loading';

export const MAIN = '/';

export const LOGIN = '/login';
export const RESET_PASSWORD = '/reset-password';
export const NEW_PASSWORD = '/new-password';
export const SIGNUP = '/signup';

export const FAQ = '/faq';
export const OLD_ACCOUNT = '/old-account';
export const NEW_ACCOUNT = '/account';

export const PORTFOLIO = '/portfolio';
export const FUND_WITHDRAW = '/fund_withdraw';
export const IMPACT = '/impact';
export const GROWTH = '/growth';
export const ONBOARDING_ALPACA = '/onboarding-alpaca';
export const KYC = '/kyc';

export const ANY = '*';
