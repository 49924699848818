import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';

import routesMeta from './meta';
import Loader from '~/ui/components/Loader/Loader';
import * as analytics from '~/utils/analytics';
import * as selectors from '@store/profile/selectors';
import { useSelector } from 'react-redux';
import { selectIsExternalUser } from '@store/application/selectors';
import { useVisits } from '~/domain/effects/application';

const getRoutes = (context) => routesMeta
  .filter(route => route.hasAccess(context))
  .map(route => route.getComponent(context));

export default function Routes () {
  const isExternalUser = useSelector(selectIsExternalUser);

  useVisits();

  useEffect(() => {
    analytics.page();
  }, []);

  const ctx = {
    hasAlpacaAccount: selectors.getHasAlpacaAccount(),
    closure: selectors.getIsAccountClosure(),
    isExternalUser,
  };

  return (
    <React.Suspense fallback={<Loader />}>
      <Switch>
        {getRoutes(ctx)}
      </Switch>
    </React.Suspense>
  );
};
