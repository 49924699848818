import {
  SET_LANG,
  SET_LANG_MAP,
  SET_LANG_ROUTE,
  SET_MEDIA_INFO,
  SET_DOMAIN,
  SET_COMPANY_DETAILS,
  SET_AUTHORIZED,
  SET_ALPACA_SIGNUP_STATUS,
} from '../types/types';
import { DEFAULT_LANG, DEFAULT_LANG_ROUTE } from '../constants/constants';

const initialState = {
  media: {
    width: 0,
    height: 0,
  },
  langMap: {},
  lang: DEFAULT_LANG,
  langRoute: DEFAULT_LANG_ROUTE,
  companyDetailsVisible: false,
  authorized: false,
  alpacaSignupStatus: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MEDIA_INFO:
      return { ...state, media: action.payload };
    case SET_LANG:
      return { ...state, lang: action.payload };
    case SET_LANG_MAP:
      return { ...state, langMap: action.payload };
    case SET_LANG_ROUTE:
      return { ...state, langRoute: action.payload };
    case SET_DOMAIN:
      return { ...state, domain: action.payload };
    case SET_COMPANY_DETAILS:
      return { ...state, companyDetailsVisible: action.payload };
    case SET_AUTHORIZED:
      return {
        ...state,
        authorized: action.payload.authorized,
      };
    case SET_ALPACA_SIGNUP_STATUS:
      return {
        ...state,
        alpacaSignupStatus: action.data,
      };
    default:
      return state;
  }
}
