import { SET_EMPLOYEE_ONBOARDING_STEP } from '~/types/types';

export const MAIN_STEP = 'main';
export const BROKER_STEP = 'broker';

const initial = {
  majorStep: MAIN_STEP,
};

export default function OnboardingEmployee (state = initial, action) {
  switch (action.type) {
    case SET_EMPLOYEE_ONBOARDING_STEP:
      return { ...state, majorStep: action.payload };
    default:
      return state;
  }
}
