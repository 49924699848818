import {
  setFullPortfolioItem,
  setFullPortfolioItemLoading,
} from '~/actions/portfolio';
import { getCompany as fetchCompany } from '~/services/company';

import { getDispatch, getState } from '@store/utils';

export const findFullPortfolioIn = (companies, ticker) => companies.find(
  company => company.ticker === ticker
);

/**
 * Get all info about company for portfolio company (short info).
 * TODO: Rewrite with saga.
 */
export const getFullCompany = async ticker => {
  const DeprecatedPortfolio = getState().DeprecatedPortfolio;
  const dispatch = getDispatch();

  let companyInfo = findFullPortfolioIn(DeprecatedPortfolio.fullPortfolio, ticker);

  if (companyInfo) {
    if (companyInfo._isLoading) {
      // TODO: Find more productive solution.
      return new Promise(resolve => {
        const checkIsLoaded = setInterval(() => {
          companyInfo = findFullPortfolioIn(DeprecatedPortfolio.fullPortfolio, ticker);

          if (!companyInfo._isLoading) {
            resolve(companyInfo);
            clearInterval(checkIsLoaded);
          }
        }, 50);
      });
    }

    return companyInfo;
  }

  let company = null;

  try {
    dispatch(setFullPortfolioItemLoading(ticker, true));
    company = await fetchCompany(ticker)();

    if (company) {
      dispatch(setFullPortfolioItem(company));
    }
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(setFullPortfolioItemLoading(ticker, false));
  }

  return company;
};
