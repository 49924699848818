import profileAPI from '@api/endpoints/profile';
import brokerApi from '@api/endpoints/broker';
import portfolio from '@api/endpoints/portfolio';
import { response } from '@api/helpers';

export async function changeInvestmentAmount2 (newAmount) {
  return profileAPI.changeProfile({
    investment_amount: newAmount,
  });
}

export function changeInvestmentAmount(payload) {
  return async () => response(profileAPI.changeProfile(payload));
}

export function getAlpacaPortfolioHistory(params) {
  return async () => {
    const qParams = {
      period: params.period,
      timeframe: '1D',
    };

    if (params.startDate) {
      qParams.date_start = params.startDate;
    }
    else {
      qParams.date_end = params.endDate;
    }

    return response(brokerApi.fetchPortfolioHistory({ params: qParams }));
  };
}

const mapPeriod = (period) => {
  switch (period) {
    case '1W': return 'WEEK';
    case '1M': return 'MONTH';
    case '1A': return 'YEAR';
    case '10A': return 'MAX';
  }
};

export function getPerformancePortfolioHistory ({ period, endDate }) {
  return async () => {
    const qParams = {
      period: mapPeriod(period),
      timeframe: '1D',
      date_end: endDate,
    };
    return response(portfolio.fetchPortfolioPerformance({ params: qParams }));
  };
}

export function getIndividualPortfolioBySymbol(symbol) {
  return async () => response(brokerApi.fetchPortfolioBySymbol(symbol));
}

export function getIndividualPerformanceGraphData(
  symbol,
  startDate,
  endDate,
  timeframe = '1Day'
) {
  return async () => {
    const params = {
      timeframe: timeframe,
      start: startDate,
      end: endDate,
    };
    return response(brokerApi.fetchIndividualPerformance({ symbol, params }));
  };
}

export function getBrokerPortfolio() {
  return async () => response(brokerApi.fetchPortfolio());
}
