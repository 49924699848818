export const // eslint-disable-line one-var
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_TOPICS = 'SET_TOPICS',
  SET_TOPICS_LOADING = 'SET_TOPICS_LOADING',
  SET_TOPIC_CATEGORIES = 'SET_TOPIC_CATEGORIES',
  SET_LANG_MAP = 'SET_LANG_MAP',
  SET_LANG_ROUTE = 'SET_LANG_ROUTE',
  SET_LANG = 'SET_LANG',
  SET_DOMAIN = 'SET_DOMAIN',
  SET_MEDIA_INFO = 'SET_MEDIA_INFO',
  SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS',
  SET_AUTHORIZED = 'SET_AUTHORIZED';

export const INCREMENT_STEP = 'INCREMENT_STEP';
export const DECREMENT_STEP = 'DECREMENT_STEP';
export const CURRENT_STEPS = 'CURRENT_STEPS';
export const SET_STEP = 'SET_STEP';
export const SET_STEP1_VALID = 'SET_STEP1_VALID';
export const SET_STEP2_VALID = 'SET_STEP2_VALID';
//
export const SET_EMPLOYEE_ONBOARDING_STEP = 'SET_EMPLOYEE_ONBOARDING_STEP';
// CARDS
export const GET_CARDS = 'GET_CARDS';
export const RECIEVE_CARDS = 'RECIEVE_CARDS';
// INPUT
export const RECIEVE_VALUE = 'RECIEVE_VALUE';
// STEP-1
export const SAVE_CARD_DETAIL = 'SAVE_CARD_DETAIL';
export const SAVE_IMPACT_TOPICS_NOT_SELECTED =
  'SAVE_IMPACT_TOPICS_NOT_SELECTED';
// STEP-3
export const SAVE_DETAIL = 'SAVE_DETAIL';
export const SAVE_SIGNUP = 'SAVE_SIGNUP';
// PORTFOLIO
export const PORTFOLIO_POPUP_LOADING = 'PORTFOLIO_POPUP_LOADING';
export const SET_FULL_PORTFOLIO_ITEM = 'SET_FULL_PORTFOLIO_ITEM';
export const SET_FULL_PORTFOLIO_ITEM_LOADING = 'SET_FULL_PORTFOLIO_ITEM_LOADING';
// SAVE-RECOMENDED
export const SAVE_RECOMENDED = 'SAVE_RECOMENDED';
export const LOGOUT = 'LOGOUT';
export const SET_ALPACA_SIGNUP_STATUS = 'SET_ALPACA_SIGNUP_STATUS';
// APP
export const SET_TITLE = 'SET_TITLE';
export const SET_STYLES = 'SET_STYLES';
export const SET_LOGO = 'SET_LOGO';
export const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';
export const SET_EMOJI_ON = 'SET_EMOJI_ON';
export const SET_SELECTION_TYPE = 'SET_SELECTION_TYPE';
export const SET_PASSWORD_CHANGE_AVAILABILITY = 'SET_PASSWORD_CHANGE_AVAILABILITY';
