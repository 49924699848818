import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as Tecton from 'q2-tecton-sdk';
import { ScrollData } from 'q2-tecton-sdk/dist/esm/sources/platformScrollChanged';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

import { modifyUserSettingAction, userSettingsSelector } from '@store/settings';
import { selectOrganization } from '@store/organization/selectors';

import { handleOuterHeightNaive, keepAlive, handleOuterHeightAware, registerOnScrollChangedExperiment } from './utils';

type TectonHandlersType = 'unknown' | 'registerOnScrollChangedExperiment' | 'handleOuterHeightAware' | 'handleOuterHeightNaive';
declare global {
  interface Window {
    tecton?: Tecton.IPlatformCoreAPI;
    ekoScrollOutsideToTop: VoidFunction;
    __tectonType?: TectonHandlersType;
    __enable_new_tecton?: VoidFunction;
    __disable_new_tecton?: VoidFunction;
    Tecton: {
      device: 'phone'| 'tablet'| 'desktop';
      inMobileApp?: boolean;
      platformDimensions?: ScrollData & {innerWidth: number}
    };
  }
}

window.ekoScrollOutsideToTop = () => {
  console.log('called default scrollOutsideToTop');
};

const Q2Id = 1;

const moduleIdParam = 'tct-id=tectonRoute.customsso.Main';

const registerTectonCallbacks = (tectonInstance: Tecton.IPlatformCoreAPI, isTectonExperiment: boolean) => {
  window.ekoScrollOutsideToTop();

  window.__tectonType = 'unknown';

  if (tectonInstance.sources.platformScrollChanged) {
    if (isTectonExperiment) {
      window.__tectonType = 'registerOnScrollChangedExperiment';
      registerOnScrollChangedExperiment(tectonInstance);
      return;
    }

    window.__tectonType = 'handleOuterHeightAware';
    handleOuterHeightAware(tectonInstance);
    return;
  }

  window.__tectonType = 'handleOuterHeightNaive';
  handleOuterHeightNaive(tectonInstance);
};

const loadTecton = (location, history, isTectonExperiment?: boolean) => {
  let urlWithModuleId;

  if (location.search) {
    if (location.search.indexOf('tct-id') === -1) {
      urlWithModuleId = location.pathname + location.search + `&${moduleIdParam}`;
    }
  }
  else {
    urlWithModuleId = location.pathname + '?' + moduleIdParam;
  }

  urlWithModuleId && history.push(urlWithModuleId);

  Tecton
    .connect({
      testOptions: window.parent === window
        ? { loadElements: false }
        : undefined
    })
    .then((tectonInstance) => {
      window.tecton = tectonInstance;

      window.ekoScrollOutsideToTop = () => {
        console.log('called real scrollOutsideToTop');

        if (typeof tectonInstance.actions.scrollToTop === 'function') {
          tectonInstance.actions.scrollToTop();
        }
      };

      document.documentElement.setAttribute(
        'device',
        window.Tecton && window.Tecton.device ? window.Tecton.device : 'unknown'
      );

      registerTectonCallbacks(tectonInstance, isTectonExperiment);

      keepAlive(tectonInstance);
      document.body.removeAttribute('data-tecton-module');
      tectonInstance.actions.setFetching(false);
      tectonInstance.actions.resizeIframe();
    }).catch((e) => {
      console.error('Failed to load Tecton', e);
    });
};

export const useTecton = () => {
  const organization = useSelector(selectOrganization);
  const location = useLocation();
  const history = useHistory();

  const userSettings = useSelector(userSettingsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    window.__enable_new_tecton = () => {
      dispatch(modifyUserSettingAction({
        experiments: ['new_tecton']
      }));
    };

    window.__disable_new_tecton = () => {
      dispatch(modifyUserSettingAction({
        experiments: []
      }));
    };
  }, []);

  useEffect(() => {
    const isTectonExperiment: boolean | undefined = pipe(
      userSettings,
      RD.fold(
        () => undefined,
        () => undefined,
        () => false,
        (settings) => (settings && settings.experiments || []).includes('new_tecton'),
      )
    );

    if (organization?.vendor === Q2Id && isTectonExperiment !== undefined) {
      loadTecton(location, history, isTectonExperiment);
    }
  }, [organization, userSettings]);
};
