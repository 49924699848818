import * as Tecton from 'q2-tecton-sdk';

import { dispatchScrollEvent } from './dispatchScrollEvent';

export const handleOuterHeightNaive = (tecton: Tecton.IPlatformCoreAPI) => {
  const hasNotch = Math.round(window.screen.width / window.screen.height * 100) === 46;
  const naiveHeight = window.screen.height - (hasNotch ? 107 : 79);
  const root = document.documentElement;
  root.style.setProperty('--q2-real-top', `0px`);
  root.style.setProperty('--q2-naive-height', `${naiveHeight}px`);
  const app = document.querySelector<HTMLElement>('#app');
  app.style.height = `calc(var(--q2-naive-height))`;
  tecton.actions.resizeIframe();
  dispatchScrollEvent();
};
