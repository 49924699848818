import { createContext, useEffect, useState } from 'react';

import { ACH_SELECTION_TYPES } from '~/constants/accounts';

const EKO_LOGO = 'https://eko-app.s3.us-east-1.amazonaws.com/eko/organizations/logos/eko_logo.svg';

const THEME_TYPE_KEY = 'theme';
const SETTINGS_TYPE_KEY = 'settings';
const STYLES_TYPE_KEY = 'styles';
const INFO_TYPE_KEY = 'info';

export const FUNDING_MINIMUM_KEY = 'account_funding_minimum';
export const DEPOSIT_MINIMUM_KEY = 'deposit_minimum';
export const WITHDRAWAL_MINIMUM_KEY = 'withdrawal_minimum';
export const ACH_SELECTION_TYPE_KEY = 'ach_selection_type';
export const IS_PASSWORD_CHANGE_AVAILABLE_KEY = 'password_change_available';

export const DISPLAY_NAME_KEY = 'displayName';

export const LOGO_KEY = 'logo';
export const EMOJI_KEY = 'emoji';

const DEFAULT_SETTINGS = {
  [INFO_TYPE_KEY]: {
    [DISPLAY_NAME_KEY]: 'Eko',
  },
  [THEME_TYPE_KEY]: {
    [LOGO_KEY]: EKO_LOGO,
    [EMOJI_KEY]: true,
  },
  [SETTINGS_TYPE_KEY]: {
    [FUNDING_MINIMUM_KEY]: 10,
    [DEPOSIT_MINIMUM_KEY]: 2,
    [WITHDRAWAL_MINIMUM_KEY]: 2,
    [ACH_SELECTION_TYPE_KEY]: ACH_SELECTION_TYPES.MANUAL,
    [IS_PASSWORD_CHANGE_AVAILABLE_KEY]: true,
  },
  [STYLES_TYPE_KEY]: '',
};

const getPositiveNumber = (value, defaultValue) => {
  const number = Number(value);

  return Number.isFinite(number) && number >= 0
    ? number
    : defaultValue;
};

const getBooleanValue = (value, defaultValue) => typeof value === 'boolean'
  ? value
  : defaultValue;

const parseCompanySettings = company => {
  const defaultTheme = DEFAULT_SETTINGS[THEME_TYPE_KEY];
  const defaultSettings = DEFAULT_SETTINGS[SETTINGS_TYPE_KEY];
  const defaultStyles = DEFAULT_SETTINGS[STYLES_TYPE_KEY];
  const defaultInfo = DEFAULT_SETTINGS[INFO_TYPE_KEY];

  return {
    [THEME_TYPE_KEY]: {
      [LOGO_KEY]: company.logo || defaultTheme[LOGO_KEY],
      [EMOJI_KEY]: company.is_emoji_enabled,
    },
    [SETTINGS_TYPE_KEY]: {
      [FUNDING_MINIMUM_KEY]: getPositiveNumber(
        company.account_funding_minimum,
        defaultSettings[FUNDING_MINIMUM_KEY],
      ),
      [DEPOSIT_MINIMUM_KEY]: getPositiveNumber(
        company.deposit_minimum,
        defaultSettings[DEPOSIT_MINIMUM_KEY],
      ),
      [WITHDRAWAL_MINIMUM_KEY]: getPositiveNumber(
        company.withdrawal_minimum,
        defaultSettings[WITHDRAWAL_MINIMUM_KEY],
      ),
      [ACH_SELECTION_TYPE_KEY]: getPositiveNumber(
        company.ach_selection,
        defaultSettings[ACH_SELECTION_TYPE_KEY],
      ),
      [IS_PASSWORD_CHANGE_AVAILABLE_KEY]: getBooleanValue(
        company.is_password_change_enabled,
        defaultSettings[IS_PASSWORD_CHANGE_AVAILABLE_KEY],
      ),
    },
    [STYLES_TYPE_KEY]: company.css || defaultStyles,
    [INFO_TYPE_KEY]: {
      [DISPLAY_NAME_KEY]: company.display_name || defaultInfo[DISPLAY_NAME_KEY],
    },
  };
};

const getCompanySettings = company => company
  ? parseCompanySettings(company)
  : DEFAULT_SETTINGS;

const getSetting = (settings, ...keys) => {
  let current = settings;

  for (let key of keys) {
    if (key in current) {
      current = current[key];
    } else {
      return null;
    }
  }

  return current;
};

const getSettingOrDefault = (settings, ...keys) => {
  let setting = getSetting(settings, ...keys);

  if (setting === null) {
    return getSetting(DEFAULT_SETTINGS, ...keys);
  }

  return setting;
};

export const getThemeKey = (settings, key) => getSettingOrDefault(
  settings,
  THEME_TYPE_KEY,
  key,
);
export const getSettingsKey = (settings, key) => getSettingOrDefault(
  settings,
  SETTINGS_TYPE_KEY,
  key,
);
export const getInfoKey = (settings, key) => getSettingOrDefault(
  settings,
  INFO_TYPE_KEY,
  key,
);

export const getTitle = settings => getSettingOrDefault(
  settings,
  INFO_TYPE_KEY,
  DISPLAY_NAME_KEY,
);
export const getPasswordChangeAvailability = settings => getSettingOrDefault(
  settings,
  SETTINGS_TYPE_KEY,
  IS_PASSWORD_CHANGE_AVAILABLE_KEY,
);
export const getACHSelectionType = settings => getSettingOrDefault(
  settings,
  SETTINGS_TYPE_KEY,
  ACH_SELECTION_TYPE_KEY,
);
export const getStyles = settings => getSettingOrDefault(
  settings,
  STYLES_TYPE_KEY,
);
export const getLogo = settings => getSettingOrDefault(
  settings,
  THEME_TYPE_KEY,
  LOGO_KEY,
);
export const getIsEmojiOn = settings => getSettingOrDefault(
  settings,
  THEME_TYPE_KEY,
  EMOJI_KEY,
);

export const CompanySettingsContext = createContext(DEFAULT_SETTINGS);

export const useSettings = (company) => {
  const [settings, setSettings] = useState(DEFAULT_SETTINGS);

  useEffect(() => {
    if (company) {
      setSettings(getCompanySettings(company));
    }
  }, [company]);

  return settings;
};
