import * as Tecton from 'q2-tecton-sdk';
import { ScrollData } from 'q2-tecton-sdk/dist/esm/sources/platformScrollChanged';
import { debounce } from 'lodash';

let isOnScrollCalled = false;

const setupViewportHeight = (innerHeight: number) => {
  const appElement = document.getElementById('app');

  if (appElement) {
    const subtractValue = 'var(--q2-header-size, 0px) - var(--q2-footer-size, 0px)';

    appElement.style.height = `calc(${innerHeight}px - ${subtractValue})`;
    appElement.style.overflowY = 'auto';

    window.ekoScrollOutsideToTop();
  }
};

export const registerOnScrollChangedExperiment = (tecton: Tecton.IPlatformCoreAPI) => {
  const reactOnScroll = ({ innerHeight }: ScrollData) => {
    const appElement = document.getElementById('app');

    if (appElement && !isOnScrollCalled) {
      setupViewportHeight(innerHeight);
      window.ekoScrollOutsideToTop();

      if (innerHeight > 200) {
        isOnScrollCalled = true;
      }
    }
  };

  const reactOnDimensionChanged = ({ innerHeight }: ScrollData) => {
    setupViewportHeight(innerHeight);
  };

  const debouncedReactOnScroll = debounce(reactOnScroll, 300);

  if (typeof tecton.sources.getPlatformDimensions === 'function') {
    tecton
      .sources
      .getPlatformDimensions()
      .then(reactOnDimensionChanged)
      .catch((e) => {
        console.error(`Failed to initiate getPlatformDimensions listener`, e);
      });
  }

  if (typeof tecton.sources.platformDimensionsChanged === 'function') {
    tecton.sources.platformDimensionsChanged(reactOnDimensionChanged);
  }

  if (typeof tecton.sources.platformScrollChanged === 'function') {
    tecton.sources.platformScrollChanged(debouncedReactOnScroll);
  }
};
