import * as types from '../types/types';
import { findFullPortfolioIn } from '~/services-2/portfolio';

const initialState = {
  portfolioDetail: [],
  portfolio: [],
  isLoading: false,
  portfolioPopupLoading: false,
  fullPortfolio: [],
};

const setFullPortfolioItem = (state, company) => {
  const companies = state.fullPortfolio;
  const companyToAdd = { ...company, _isLoading: false };
  const fullPortfolioItem = findFullPortfolioIn(
    state.fullPortfolio,
    company.ticker,
  );

  if (fullPortfolioItem) {
    companies[companies.indexOf(fullPortfolioItem)] = companyToAdd;
  } else {
    companies.push(companyToAdd);
  }

  return { ...state, fullPortfolio: companies };
};

const setFullPortfolioItemLoading = (state, ticker, isLoading) => {
  const { fullPortfolio } = state;
  const fullPortfolioItem = findFullPortfolioIn(state.fullPortfolio, ticker);

  if (fullPortfolioItem) {
    if (fullPortfolioItem._isLoading !== isLoading) {
      fullPortfolio[fullPortfolio.indexOf(fullPortfolioItem)] = {
        ...fullPortfolioItem,
        _isLoading: isLoading,
      };
    }
  } else {
    fullPortfolio.push({ ticker: ticker, _isLoading: isLoading });
  }

  return { ...state, fullPortfolio };
};

const DeprecatedPortfolio = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FULL_PORTFOLIO_ITEM:
      return setFullPortfolioItem(state, action.company);

    case types.SET_FULL_PORTFOLIO_ITEM_LOADING:
      return setFullPortfolioItemLoading(
        state,
        action.payload.ticker,
        action.payload.isLoading,
      );

    case types.PORTFOLIO_POPUP_LOADING:
      return { ...state, portfolioPopupLoading: action.isLoading };

    default: {
      return state;
    }
  }
};
export default DeprecatedPortfolio;
